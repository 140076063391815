import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import NavLeft from "../data/NavLeft";
import NavRight from "../data/NavRight";
import config from "../../../data/SiteConfig";

const FooterWrapper = styled.footer`
  font-family: ${(props) => props.theme.fonts.body}, sans-serif;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  position: relative;
  z-index: 2;
  height: auto;
  display: flex;
  align-items: center;
  padding: 3em 2em;
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    padding: 3.5em 2em;
    height: 50vh;
  }
`;

const FooterOuter = styled.section`
  position: relative;
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    max-width: ${(props) => props.theme.sizes.maxWidth};
  }
  .text-light {
    margin-top: 20px;
    color: ${(props) => props.theme.colors.white};
    font-size: 1rem;
    font-size: 11px;
    font-weight: ${(props) => props.theme.fontWeights.light};
    text-align: left;
    span {
      margin-right: 0.5rem;
    }
    a {
      color: ${(props) => props.theme.colors.white};
      text-decoration: none;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
`;

const FooterInner = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    display: flex;
    flex-flow: row wrap;
  }
  .c-column {
    width: 100%;
    margin-bottom: 3em;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 20%;
      position: relative;
    }
    ul {
      list-style: none;
      li {
        opacity: 0.6;
        cursor: pointer;
        font-size: 14px;
        font-weight: 300;
        padding: 0.34vw 0;
        position: relative;
        will-change: opacity;
        backface-visibility: hidden;
        transition: opacity 250ms ease-out;
        &:hover {
          opacity: 1;
        }
        a {
          color: ${(props) => props.theme.colors.white};
          outline: none;
          text-decoration: none;
        }
        .adress__email {
          text-decoration: underline;
        }
      }
    }
  }
  .c-column h3 {
    font-family: ${(props) => props.theme.fonts.LarkenThin}, sans-serif;
    margin-bottom: 2vw;
    font-size: 20px;
    line-height: 5.33vw;
    letter-spacing: 0.26vw;
    @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
      margin-bottom: 1vw;
      font-size: 22px;
      line-height: 1.52vw;
      letter-spacing: 0.03vw;
    }
  }
`;

const Footer = () => {
  const {
    userLinks,
    userAddressWavrin,
    userAddressLille,
    userAddressMarquette,
    userEmail,
    userEmailUrl,
    userCellPhone,
    userCellPhoneUrl,
    userBackLinks,
  } = config;
  // console.log(userAddressWavrin, userAddressLille, userAddressMarquette)
  return (
    <FooterWrapper>
      <FooterOuter>
        <FooterInner>
          <div className="c-column">
            <h3>Aub'Orescence&reg;</h3>
            <ul>
              {NavLeft &&
                NavLeft.map((nav) => {
                  return (
                    <li key={nav.id}>
                      <Link to={`${nav.slug}`}>{nav.name}</Link>
                    </li>
                  );
                })}
              {NavRight &&
                NavRight.map((nav) => {
                  return (
                    <li key={nav.id}>
                      <Link to={`${nav.slug}`}>{nav.name}</Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="c-column">
            <h3>Réseaux sociaux</h3>
            <ul>
              {userLinks &&
                userLinks.map((link) => {
                  return (
                    <li key={link.label}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.label}
                      </a>
                    </li>
                  );
                })}
            </ul>
            <ul>
              {userBackLinks &&
                userBackLinks.map((link) => {
                  return (
                    <li key={link.label}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.label}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="c-column">
            <h3>{userAddressWavrin.name}</h3>
            <ul className="address">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userAddressWavrin.googleMaps}
                >
                  {userAddressWavrin.street}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userAddressWavrin.googleMaps}
                >
                  {userAddressWavrin.city}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userCellPhoneUrl}
                >
                  {userCellPhone}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userEmailUrl}
                  className="adress__email"
                >
                  {userEmail}
                </a>
              </li>
            </ul>
          </div>

          <div className="c-column">
            <h3>{userAddressMarquette.name}</h3>
            <ul className="address">
              {/* <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userAddressMarquette.googleMaps}
                >
                  {userAddressMarquette.businessName}
                </a>
              </li> */}
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userAddressMarquette.googleMaps}
                >
                  {userAddressMarquette.street}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userAddressMarquette.googleMaps}
                >
                  {userAddressMarquette.city}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userCellPhoneUrl}
                >
                  {userCellPhone}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userEmailUrl}
                  className="adress__email"
                >
                  {userEmail}
                </a>
              </li>
            </ul>
          </div>
          <div className="c-column">
            <h3>{userAddressLille.name}</h3>
            <ul className="address">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userAddressLille.googleMaps}
                >
                  {userAddressLille.street}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userAddressLille.googleMaps}
                >
                  {userAddressLille.city}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userCellPhoneUrl}
                >
                  {userCellPhone}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userEmailUrl}
                  className="adress__email"
                >
                  {userEmail}
                </a>
              </li>
            </ul>
          </div>
        </FooterInner>
        <p className="text-light">
          <span>
            Copyright &copy; {new Date().getFullYear()} Aub'Orescence® - Anaïs
            LIEFOOGHE. Tous droits réservés.
          </span>
          | <Link to="/mentions-legales/">Mentions légales</Link> |{" "}
          <Link to="/credits-photos/">Crédits photos</Link>
        </p>
      </FooterOuter>
    </FooterWrapper>
  );
};

export default Footer;
