import React, { useContext } from 'react'
import { AppContext } from '../../context/AppContext'

import Nav from './Nav'

import NavLeft from '../data/NavLeft'
import NavRight from '../data/NavRight'

const MobileNavLinks = () => {
  const { mobileNavOpen, toggleMobileNav } = useContext(AppContext)

  const close = () => {
    // setMobileNavOpen(false)
    // setSubNavOpen(!subNavOpen)
    toggleMobileNav(!mobileNavOpen)
    document.documentElement.classList.remove('contain')
  }
  return (
    <>
      <Nav
        navPosition="flex-start"
        navData={NavLeft}
        close={close}
        mobileNavOpen={mobileNavOpen}
        // subNavOpen={subNavOpen}
      />
      <Nav
        navPosition="flex-end"
        navData={NavRight}
        close={close}
        mobileNavOpen={mobileNavOpen}
      />
    </>
  )
}

export default MobileNavLinks
