import { css } from "@emotion/react"
import VariableFont from '../static/fonts/Inter-upright.woff2'
import LarkenThin from'../static/fonts/LarkenThin/font.woff2'
import LarkenThinItalic from '../static/fonts/LarkenThinItalic/font.woff2'
import LarkenLight from'../static/fonts/LarkenLight/font.woff2'
import LarkenLightItalic from '../static/fonts/LarkenLightItalic/font.woff2'
import LarkenRegular from'../static/fonts/LarkenRegular/font.woff2'
import LarkenItalic from'../static/fonts/LarkenItalic/font.woff2'

export const globalStyles = css`
/**
 * @license
 * MyFonts Webfont Build ID 4087966, 2021-05-12T17:08:19-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Larken-Thin by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/thin/
 * 
 * Webfont: Larken-ThinItalic by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/thin-italic/
 * 
 * Webfont: Larken-Light by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/light/
 * 
 * Webfont: Larken-LightItalic by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/light-italic/
 * 
 * Webfont: Larken-Regular by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/regular/
 * 
 * Webfont: Larken-Italic by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/italic/
 * 
 * Webfont: Larken-Medium by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/medium/
 * 
 * Webfont: Larken-MediumItalic by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/medium-italic/
 * 
 * Webfont: Larken-Bold by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/bold/
 * 
 * Webfont: Larken-BoldItalic by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/bold-italic/
 * 
 * Webfont: Larken-ExtraBold by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/extra-bold/
 * 
 * Webfont: Larken-ExtraBoldItalic by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/extra-bold-italic/
 * 
 * Webfont: Larken-Black by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/black/
 * 
 * Webfont: Larken-BlackItalic by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/black-italic/
 * 
 * Webfont: LarkenVariable by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/variable/
 * 
 * Webfont: LarkenVariableItalic by EllenLuff
 * URL: https://www.myfonts.com/fonts/ellenluff/larken/variable-italic/
 * 
 * 
 * Webfonts copyright: Copyright © 2020 by Tom Anders Watkins &amp; Ellen Luff. All rights reserved.
 * 
 * © 2021 MyFonts Inc
*/

@font-face {
  font-family: 'Larken-Thin';
  src: url(${LarkenThin}) format('woff2');
}

@font-face {
  font-family: 'Larken-ThinItalic';
  src: url(${LarkenThinItalic}) format('woff2');
}

@font-face {
  font-family: 'Larken-Light';
  src: url(${LarkenLight}) format('woff2');
}

@font-face {
  font-family: 'Larken-LightItalic';
  src: url(${LarkenLightItalic}) format('woff2');
}

@font-face {
  font-family: 'Larken-Regular';
  src: url(${LarkenRegular}) format('woff2');
}

@font-face {
  font-family: 'Larken-Italic';
  src: url(${LarkenItalic}) format('woff2');
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: fallback;
  font-style: normal;
  src: url(${VariableFont}) format('woff2');
}


/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   /* stylelint-disable-next-line */
   font: inherit;
   vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
body {
  line-height: 1.5;
  font-size: 100%;
}
img,
video {
  display: block;
  width: 100%;
  height: auto;
}
button,
input {
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: none;
  outline: none;
  appearance: none;
  border-radius: 0;
  resize: none;
  &:focus {
    outline: none;
  }
  &:invalid {
    box-shadow: none;
  }
}

/* Added to prevent scrolling when the menu is open */
.contain {
  overflow: hidden;
}

/* Vertical line animation */
@keyframes vert_line {
  0% {
    transform-origin: top left;
    transform: scale(1, 0);
    animation-timing-function: cubic-bezier(0.48, 0.02, 0, 0.99); 
  }
  30% {
    transform-origin: top left;
    transform: scale(1, 1); 
  }
  41% {
    transform-origin: bottom left;
    transform: scale(1, 1);
    animation-timing-function: cubic-bezier(0.48, 0.02, 0, 0.99); 
  }
  100% {
    transform-origin: bottom left;
    transform: scale(1, 0); 
  } 
}

button,
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: none;
  appearance: none;
  /* stylelint-disable-next-line */
  -webkit-appearance: none;
  /* stylelint-disable-next-line */
  -moz-appearance: none;
  border-radius: 0;
  resize: none;
  &:invalid {
    box-shadow: none;
  }
  &:focus {
    outline: 5px auto #5e9ed6;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus {
  outline: none;
}
`
