const NavRight = [
  {
    id: '1',
    name: 'A propos',
    slug: '/a-propos/',
  },
  {
    id: '2',
    name: 'Tarifs et cabinets',
    slug: '/tarifs-et-cabinets/tarifs/',
    subMenu: [
      {
        name: `Les tarifs`,
        link: `/tarifs-et-cabinets/tarifs/`,
      },
      {
        name: `Les cabinets`,
        link: `/tarifs-et-cabinets/cabinets/`,
      },
      {
        name: `Les mutuelles`,
        link: `/tarifs-et-cabinets/mutuelles/`,
      },
    ],
  },
  {
    id: '3',
    name: 'Contact',
    slug: '/contact/',
  },
  {
    id: '4',
    name: 'Blog',
    slug: '/blog/',
  },
]

export default NavRight
