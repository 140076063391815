const NavLeft = [
  {
    id: '1',
    name: 'Home',
    slug: '/',
  },
  {
    id: '2',
    name: 'Naturopathe',
    slug: '/naturopathe/la-naturopathie/',
    subMenu: [
      {
        name: `Qu'est-ce que la naturopathie`,
        link: `/naturopathe/la-naturopathie/`,
      },
      {
        name: `Déroulement des séances`,
        link: `/naturopathe/deroulement-des-seances/`,
      },
      {
        name: `Pourquoi consulter un naturopathe ?`,
        link: `/naturopathe/pourquoi-consulter-un-naturopathe/`,
      },
    ],
  },
  {
    id: '3',
    name: 'Reiki',
    slug: '/reiki/le-reiki/',
    subMenu: [
      {
        name: `Qu'est-ce que le Reïki ?`,
        link: `/reiki/le-reiki/`,
      },
      {
        name: `Le soin Reïki en cabinet`,
        link: `/reiki/soin-reiki-en-cabinet/`,
      },
      {
        name: `Le soin Reïki à distance`,
        link: `/reiki/soin-reiki-a-distance/`,
      },
      {
        name: `Le soin Reïki en entreprise`,
        link: `/reiki/soin-reiki-en-entreprise/`,
      },
    ],
  },
  {
    id: '4',
    name: `Aub'Orescence®`,
    // slug: '/methode-auborescence/',
    slug: '/auborescence/la-methode-auborescence/',
    subMenu: [
      {
        name: `La méthode Aub'Orescence®`,
        link: `/auborescence/la-methode-auborescence/`,
      },
      {
        name: `Soins bien-être et coaching`,
        link: `/auborescence/soins-bien-etre-et-coaching/`,
      },
      {
        name: `Déroulement des soins et des séances`,
        link: `/auborescence/deroulement-des-soins-et-des-seances/`,
      },
    ],
  },
  {
    id: '5',
    name: 'Formations Reiki et LaHoChi',
    slug: '/formations-reiki-usui-et-lahochi/reiki-usui-niveau-un/',
    subMenu: [
      {
        name: `Reïki Usui niveau 1`,
        link: `/formations-reiki-usui-et-lahochi/reiki-usui-niveau-un/`,
      },
      {
        name: `Reïki Usui niveau 2`,
        link: `/formations-reiki-usui-et-lahochi/reiki-usui-niveau-deux/`,
      },
      {
        name: `Reïki Usui niveau 3`,
        link: `/formations-reiki-usui-et-lahochi/reiki-usui-niveau-trois/`,
      },
      {
        name: `Reïki Usui niveau 4`,
        link: `/formations-reiki-usui-et-lahochi/reiki-usui-niveau-quatre/`,
      },
      {
        name: `LaHochi`,
        link: `/formations-reiki-usui-et-lahochi/lahochi-formation-complete/`,
      },
    ],
  },
]

export default NavLeft
