import React, { useContext, useEffect } from 'react'
import { Global } from '@emotion/react'
import { Helmet } from 'react-helmet'
import { globalStyles } from '../../styles/globalSyles.js'
import { AppContext } from '../../context/AppContext'

import Navigation from '../general/Navigation'
import Footer from '../general/Footer'

const Layout = (props) => {
  const { setShowMobileNavMenu } = useContext(AppContext)

  useEffect(() => {
    if (window.innerWidth > 800) {
      setShowMobileNavMenu(false)
    } else if (window.innerWidth < 800) {
      setShowMobileNavMenu(true)
    }
  }, [setShowMobileNavMenu])

  return (
    <>
      <Global styles={globalStyles} />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <html lang="fr" />
      </Helmet>
      <Navigation />
      {props.children}
      <Footer />
    </>
  )
}

export default Layout
