import React, { useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { AppContext } from '../../context/AppContext'

import NavLogo from './NavLogo'
import Nav from './Nav'
import NavLeft from '../data/NavLeft'
import NavRight from '../data/NavRight'
import MobileNavLinks from '../general/MobileNavLinks'

const StyledSiteHead = styled.header`
  font-family: ${(props) => props.theme.fonts.body}, sans-serif;
  position: fixed;
  width: 100%;
  z-index: 5;
  font-size: 1.35rem;
  padding: 3vw 6vw;
  background: ${(props) => props.theme.colors.white};
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    padding: 0 3vw 0 3vw;
    background: transparent;
  }
`

const StyledSiteHeadContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: height 0.2s ease-in;
  height: ${(props) => (props.open ? '100vh' : '80px')};
  overflow: hidden;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 210px;
  }
`
const Toggle = styled.button`
  margin: 0;
  padding: 12px 12px 12px 0;
  z-index: 999;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  position: absolute;
  left: 0;
  .hamburger-box {
    width: 20px;
    height: 13px;
  }
  span {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    display: block;
    background: ${(props) =>
      props.open ? props.theme.colors.black : props.theme.colors.gold};
    width: 20px;
    height: 1px;
    border-radius: 4px;
  }
  span:first-of-type {
    transform: rotate(${(props) => (props.open ? '0' : '0')});
    position: relative;
    top: ${(props) => (props.open ? '10px' : '0')};
    opacity: ${(props) => (props.open ? '0' : '1')};
  }
  span:nth-of-type(2n) {
    transform: rotate(${(props) => (props.open ? '-135deg' : '0')});
    position: relative;
    top: ${(props) => (props.open ? '6px' : '5px')};
  }
  span:nth-of-type(3n) {
    position: relative;
    transform: rotate(${(props) => (props.open ? '-45deg' : '0')});
    top: ${(props) => (props.open ? '5px' : '10px')};
  }
`

const Navigation = () => {
  const {
    mobileNavOpen,
    showMobileNavMenu,
    toggleMobileNav,
    toggleMobileNavView,
  } = useContext(AppContext)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 800) {
        // console.log('Hello Desktop')
        toggleMobileNav(false)
        toggleMobileNavView(false)
      } else if (window.innerWidth < 800) {
        // console.log('Hello Mobile')
        toggleMobileNavView(true)
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [toggleMobileNav, toggleMobileNavView])

  const toggle = () => {
    toggleMobileNav(!mobileNavOpen)
    document.documentElement.classList.toggle('contain')
  }

  const close = () => {
    // setMobileNavOpen(false)
    // setSubNavOpen(!subNavOpen)
    toggleMobileNav(!mobileNavOpen)
    document.documentElement.classList.remove('contain')
  }

  return (
    <>
      <StyledSiteHead>
        <StyledSiteHeadContainer open={mobileNavOpen}>
          <NavLogo />
          {showMobileNavMenu ? (
            <Toggle
              open={mobileNavOpen}
              onClick={toggle}
              aria-label="Toggle Mobile Menu Button"
            >
              <div className="hamburger-box">
                <span />
                <span />
                <span />
              </div>
            </Toggle>
          ) : (
            <>
              <Nav
                navPosition="flex-start"
                navData={NavLeft}
                close={close}
                mobileNavOpen={mobileNavOpen}
                order={1}
                // subNavOpen={subNavOpen}
              />

              <Nav
                navPosition="flex-end"
                navData={NavRight}
                close={close}
                mobileNavOpen={mobileNavOpen}
                order={3}
              />
            </>
          )}
          {showMobileNavMenu ? <MobileNavLinks open={mobileNavOpen} /> : null}
        </StyledSiteHeadContainer>
      </StyledSiteHead>
    </>
  )
}

export default Navigation
