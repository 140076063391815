import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const StyledSiteHead = styled.nav`
  position: relative;
  z-index: ${(props) => (props.open ? "9999" : "100")};
  height: auto;
  order: ${(props) => props.order};
  white-space: nowrap;
  width: 100%;
  margin: 0;
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    list-style: none;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      flex-direction: row;
      justify-content: ${(props) => props.navPosition};
    }
  }
  li {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1.6em;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      margin: 0 14px;
    }
    a.active::after {
      transform: translateX(0);
      background-color: ${(props) => props.theme.colors.darkGold};
    }
  }

  li a {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 8px 0;
    padding: 2px 0;
    font-size: 1.6rem;
    line-height: 1.15em;
    font-weight: 200;
    color: ${(props) => props.theme.colors.gold};
    text-decoration: none;
    opacity: ${(props) => (props.open ? "1" : "0")};
    transition: transform 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      opacity 0.9s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    &::after {
      display: block;
      content: "";
      height: 1px;
      background-color: ${(props) => props.theme.colors.gold};
      position: absolute;
      bottom: 0;
      width: 100%;
      transform: translateX(-101%);
    }
    &.active {
      color: ${(props) => props.theme.colors.darkGold};
    }
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      opacity: 1;
      margin: 0;
      font-weight: 400;
      font-size: 0.8rem;
      &:hover {
        color: ${(props) => props.theme.colors.darkGold};
      }
      &:hover::after {
        transition: transform 0.3s ${(props) => props.theme.animation.easing};
        transform: translateX(0);
        background-color: ${(props) => props.theme.colors.darkGold};
      }
      &.animate-out::after {
        transition: transform 0.3s ${(props) => props.theme.animation.easing};
        transform: translateX(100%);
      }
    }
  }
`;

// const StyledSubNav = styled.div``

const Nav = ({ navPosition, navData, mobileNavOpen, close, order }) => {
  const mainNavLinks = useRef([]);
  mainNavLinks.current = [];

  useEffect(() => {
    mainNavLinks.current.forEach((link) => {
      link.addEventListener("mouseleave", () => {
        link.classList.add("animate-out");
      });
      link.ontransitionend = function () {
        link.classList.remove("animate-out");
      };
    });
  }, []);

  useEffect(() => {
    const navAnimation = (direction) => {
      const scrollingDown = direction === 1;
      // console.log(direction)
      return gsap.to(mainNavLinks.current, {
        duration: 0.3,
        stagger: 0.1,
        opacity: () => (scrollingDown ? 0 : 1),
        y: () => (scrollingDown ? 20 : 0),
        ease: "power4.out",
      });
    };
    ScrollTrigger.matchMedia({
      // desktop
      "(min-width: 800px)": function () {
        // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
        // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        ScrollTrigger.create({
          start: 100,
          end: "bottom bottom-=20",
          onEnter: ({ direction }) => navAnimation(direction),
          onLeaveBack: ({ direction }) => navAnimation(direction),
          // markers: true,
        });
      },
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !mainNavLinks.current.includes(el)) {
      mainNavLinks.current.push(el);
    }
  };

  return (
    <>
      <StyledSiteHead
        navPosition={navPosition}
        open={mobileNavOpen}
        order={order}
      >
        <ul>
          {navData &&
            navData.map((nav) => {
              return (
                <li
                  key={nav.id}
                  className="nav-home nav-current"
                  ref={addToRefs}
                >
                  <Link
                    to={`${nav.slug}`}
                    activeClassName="active"
                    onClick={close}
                  >
                    {nav.name}
                  </Link>
                  {/* <StyledSubNav>
                    <ul className="row">
                      {subNavOpen ? (
                        nav.subMenu && nav.subMenu.length > 0 ? (
                          <li aria-label="submenu" className="column">
                            {nav.subMenu.map((subLink) => {
                              return (
                                <div
                                  key={subLink.name}
                                  className="dropdown-item"
                                >
                                  <Link to={`${subLink.link}`}>
                                    {subLink.name}
                                  </Link>
                                </div>
                              )
                            })}
                          </li>
                        ) : null
                      ) : null}
                    </ul>
                  </StyledSubNav> */}
                </li>
              );
            })}
        </ul>
      </StyledSiteHead>
    </>
  );
};

export default Nav;
