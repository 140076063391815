const config = {
  siteTitle: "Aub'Orescence",
  siteUrl: "https://www.auborescence.com",
  userName: "Anaïs LIEFOOGHE",
  // userEmail: 'contact@auborescence.com',
  userEmail: "contact@auborescence.com",
  userEmailUrl: "mailto:contact@auborescence.com",
  userDoctolib: "Réserver et planifier mon rendez-vous",
  userDoctolibUrl:
    "https://calendly.com/auborescence?background_color=f7f8f8&text_color=3c3c3c&primary_color=debf99%27}",
  userCellPhone: "+33 6 62 02 95 69",
  userCellPhoneUrl: "tel:+36662029569",
  pathPrefix: "/",
  siteDescription:
    "Coaching bonheur et bien être, naturopathe, soin énergétique Reiki / LaHoChi, formations, massage aux pierres chaudes, particuliers, entreprises, Lille.",

  userAddressWavrin: {
    name: "Wavrin",
    street: "96 Rue Achille Pinteaux",
    city: "59136 WAVRIN",
    googleMaps: "https://goo.gl/maps/JvgY2uSwtYiituTz7",
  },

  userAddressLille: {
    name: "Lille",
    street: "Rue de Valmy",
    city: "59000 LILLE",
    googleMaps: "https://goo.gl/maps/oSbyawSx7eSX7UjB9",
  },

  userAddressMarquette: {
    name: "Lille / Lambersart",
    businessName: "",
    street: "5 avenue de Bretagne",
    city: "59800 LILLE",
    googleMaps: "https://goo.gl/maps/y4G1W6QoGacJj4F48",
  },
  userLinks: [
    {
      label: "Instagram",
      url: "https://www.instagram.com/aub.orescence",
    },
    {
      label: "Facebook",
      url: " https://www.facebook.com/Aub.orescence ",
    },
    // {
    //   label: 'Linkedin',
    //   url: 'https://www.linkedin.com/in/anaïs-liefooghe-251438219',
    // },
    {
      label: "Twitter",
      url: "https://twitter.com/AOrescence",
    },
  ],
  userBackLinks: [
    // {
    //   label: 'Malt',
    //   url: 'https://www.malt.fr/profile/anaisliefooghe?overview=true',
    // },
    {
      label: "Linkedin",
      url: "https://www.linkedin.com/in/anaïs-liefooghe-251438219",
    },
    {
      label: "Jesuiscoach",
      url: "https://www.jesuiscoach.fr/coach/7358",
    },

    {
      label: "Annuaire Santé",
      url: "https://www.annuairesante.com/homeopathe/lille-59000",
    },
    {
      label: "Proxi Bien être",
      url: "https://www.proxibienetre.fr/acteur-bien-etre/8863",
    },
  ],
};

module.exports = config;
