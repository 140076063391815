import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const StyledSiteHeadCenter = styled.div`
  order: 0;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    order: 2;
  }
  .site-head-logo {
    flex-shrink: 0;
    display: block;
    .SVGName {
      fill: ${(props) => props.theme.colors.gold};
    }
    .SGVsunLine {
      stroke: ${(props) => props.theme.colors.gold};
    }
    .SVGCircle {
      stroke: ${(props) => props.theme.colors.gold};
    }
    .SVGcontainer {
      margin-top: -22px;
    }
  }
  .site-head-logo svg {
    display: block;
    width: auto;
    height: 125px;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      height: 220px;
      margin-top: 2px;
    }
  }
`;

const NavLogo = () => {
  const logoRef = useRef(null);

  useEffect(() => {
    const logoAnimation = (direction) => {
      const scrollingDown = direction === 1;
      // console.log(direction)
      return gsap.to(logoRef.current, {
        delay: 0.5,
        duration: 0.3,
        opacity: () => (scrollingDown ? 0 : 1),
        y: () => (scrollingDown ? 20 : 0),
        ease: "power4.out",
      });
    };
    ScrollTrigger.matchMedia({
      "(min-width: 800px)": function () {
        // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
        // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        ScrollTrigger.create({
          start: 100,
          end: "bottom bottom-=20",
          onEnter: ({ direction }) => logoAnimation(direction),
          onLeaveBack: ({ direction }) => logoAnimation(direction),
          // markers: true,
        });
      },
    });
  }, []);
  return (
    <>
      <StyledSiteHeadCenter ref={logoRef}>
        <Link className="site-head-logo" to="/">
          <div className="SVGcontainer">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 283.465 198.425"
            >
              <g>
                <g className="SVGName">
                  <path
                    d="M73.397,148.506c0,0.148-0.049,0.264-0.148,0.346s-0.222,0.123-0.37,0.123c-0.115,0-0.218-0.028-0.308-0.087
			c-0.09-0.057-0.152-0.135-0.185-0.233l-2.071-5.079l0.025,0.049h-8.259l-1.997,5.03c-0.082,0.213-0.23,0.32-0.444,0.32
			c-0.147,0-0.267-0.041-0.357-0.123s-0.144-0.181-0.16-0.296c-0.017-0.065-0.008-0.148,0.025-0.247l6.632-16.32
			c0.049-0.132,0.111-0.218,0.185-0.259c0.074-0.041,0.168-0.062,0.284-0.062c0.247,0,0.411,0.107,0.493,0.321l6.632,16.32
			C73.389,148.359,73.397,148.424,73.397,148.506z M62.475,142.639h7.47l-3.772-9.295L62.475,142.639z"
                  />
                  <path
                    d="M85.711,136.821c0.09,0.099,0.136,0.214,0.136,0.345v7.175c0,1.562-0.44,2.748-1.319,3.562
			c-0.879,0.813-2.075,1.22-3.587,1.22s-2.704-0.406-3.575-1.22c-0.872-0.814-1.307-2.001-1.307-3.562v-7.175
			c0-0.131,0.049-0.246,0.148-0.345s0.213-0.148,0.345-0.148c0.148,0,0.267,0.05,0.357,0.148c0.09,0.099,0.135,0.214,0.135,0.345
			v7.175c0,1.232,0.345,2.182,1.036,2.847c0.69,0.666,1.644,0.999,2.86,0.999c1.233,0,2.194-0.333,2.884-0.999
			c0.69-0.665,1.036-1.614,1.036-2.847v-7.175c0-0.131,0.045-0.246,0.136-0.345c0.09-0.099,0.209-0.148,0.357-0.148
			S85.621,136.722,85.711,136.821z"
                  />
                  <path
                    d="M98.593,137.104c0.871,0.551,1.553,1.315,2.046,2.293s0.74,2.075,0.74,3.291c0,1.217-0.251,2.322-0.752,3.316
			c-0.502,0.994-1.188,1.779-2.059,2.354c-0.872,0.575-1.841,0.862-2.909,0.862c-0.97,0-1.866-0.259-2.688-0.776
			s-1.504-1.236-2.046-2.157v2.194c0,0.132-0.045,0.246-0.135,0.345c-0.091,0.099-0.209,0.148-0.357,0.148
			c-0.132,0-0.247-0.05-0.345-0.148s-0.148-0.213-0.148-0.345v-17.233c0-0.131,0.045-0.246,0.135-0.345s0.21-0.148,0.358-0.148
			s0.267,0.05,0.357,0.148c0.09,0.099,0.135,0.214,0.135,0.345v8.037c0.493-0.97,1.155-1.713,1.985-2.23
			c0.83-0.519,1.746-0.777,2.749-0.777C96.744,136.278,97.722,136.553,98.593,137.104z M98.05,147.545
			c0.723-0.493,1.294-1.163,1.713-2.01c0.419-0.847,0.629-1.796,0.629-2.848s-0.209-1.992-0.629-2.822
			c-0.419-0.83-0.99-1.479-1.713-1.948s-1.537-0.702-2.44-0.702c-0.921,0-1.747,0.233-2.478,0.702s-1.298,1.118-1.701,1.948
			s-0.604,1.771-0.604,2.822s0.206,2.006,0.617,2.86c0.411,0.854,0.978,1.524,1.701,2.009c0.723,0.485,1.544,0.728,2.465,0.728
			C96.514,148.285,97.327,148.038,98.05,147.545z"
                  />
                  <path
                    d="M105.557,134.072c-0.222,0.436-0.502,0.78-0.838,1.035c-0.337,0.255-0.653,0.382-0.949,0.382
			c-0.115,0-0.218-0.032-0.308-0.099c-0.09-0.065-0.136-0.164-0.136-0.295c0-0.148,0.033-0.247,0.099-0.296
			c0.065-0.05,0.185-0.099,0.357-0.148c0.173-0.049,0.308-0.099,0.407-0.147c0.23-0.115,0.407-0.296,0.53-0.543
			c0.123-0.246,0.185-0.501,0.185-0.764c0-0.395-0.14-0.592-0.419-0.592c-0.247,0-0.46,0.073-0.641,0.222
			c-0.132-0.164-0.197-0.329-0.197-0.493c0-0.214,0.111-0.402,0.333-0.567c0.222-0.164,0.472-0.246,0.752-0.246
			c0.345,0,0.625,0.107,0.838,0.32c0.213,0.214,0.32,0.493,0.32,0.838C105.89,133.171,105.779,133.636,105.557,134.072z"
                  />
                  <path
                    d="M123.332,144.956c-0.699,1.348-1.673,2.396-2.921,3.144c-1.25,0.748-2.671,1.121-4.265,1.121
			c-1.595,0-3.017-0.373-4.265-1.121c-1.25-0.748-2.223-1.796-2.922-3.144s-1.047-2.885-1.047-4.61s0.349-3.263,1.047-4.61
			s1.672-2.396,2.922-3.144c1.249-0.748,2.67-1.122,4.265-1.122c1.594,0,3.016,0.374,4.265,1.122
			c1.249,0.748,2.223,1.796,2.921,3.144c0.698,1.348,1.048,2.885,1.048,4.61S124.031,143.609,123.332,144.956z M122.432,136.229
			c-0.608-1.2-1.459-2.129-2.552-2.786c-1.093-0.657-2.338-0.986-3.735-0.986c-1.397,0-2.643,0.329-3.735,0.986
			c-1.093,0.657-1.944,1.586-2.552,2.786s-0.912,2.572-0.912,4.117s0.304,2.917,0.912,4.117s1.458,2.129,2.552,2.786
			c1.093,0.657,2.338,0.985,3.735,0.985c1.396,0,2.642-0.328,3.735-0.985c1.093-0.657,1.943-1.586,2.552-2.786
			c0.608-1.2,0.912-2.572,0.912-4.117S123.04,137.429,122.432,136.229z"
                  />
                  <path
                    d="M134.365,136.845c0,0.147-0.041,0.271-0.123,0.37c-0.083,0.099-0.189,0.147-0.321,0.147
			c-0.049,0-0.168-0.037-0.357-0.11c-0.189-0.074-0.391-0.111-0.604-0.111c-0.674,0-1.348,0.268-2.021,0.801
			c-0.674,0.534-1.225,1.208-1.652,2.022c-0.428,0.813-0.641,1.599-0.641,2.354v6.164c0,0.132-0.049,0.246-0.148,0.345
			s-0.213,0.148-0.345,0.148c-0.148,0-0.268-0.05-0.358-0.148s-0.135-0.213-0.135-0.345v-11.316c0-0.131,0.049-0.246,0.148-0.345
			s0.213-0.148,0.345-0.148c0.148,0,0.267,0.05,0.357,0.148c0.09,0.099,0.135,0.214,0.135,0.345v2.613
			c0.378-1.002,0.965-1.832,1.763-2.49c0.797-0.657,1.738-0.994,2.823-1.011C133.987,136.278,134.365,136.467,134.365,136.845z"
                  />
                  <path
                    d="M146.223,146.152c0.082,0.091,0.123,0.193,0.123,0.309s-0.033,0.214-0.099,0.296
			c-0.542,0.723-1.216,1.314-2.021,1.774s-1.668,0.69-2.589,0.69c-1.232,0-2.326-0.263-3.279-0.789
			c-0.954-0.525-1.697-1.273-2.231-2.243c-0.535-0.97-0.801-2.096-0.801-3.378c0-1.314,0.259-2.469,0.776-3.464
			c0.518-0.994,1.204-1.754,2.059-2.28c0.854-0.525,1.767-0.789,2.737-0.789c0.969,0,1.869,0.21,2.699,0.629
			s1.508,1.048,2.034,1.886c0.526,0.839,0.806,1.874,0.839,3.106c0,0.132-0.05,0.251-0.148,0.357
			c-0.099,0.107-0.214,0.16-0.345,0.16h-9.689v0.321c0,1.019,0.205,1.951,0.616,2.798c0.411,0.847,1.019,1.517,1.824,2.01
			c0.805,0.492,1.775,0.739,2.909,0.739c0.789,0,1.533-0.193,2.231-0.579c0.698-0.387,1.245-0.876,1.64-1.468
			c0.114-0.147,0.255-0.222,0.419-0.222C146.043,146.016,146.141,146.062,146.223,146.152z M139.036,137.745
			c-0.633,0.321-1.191,0.798-1.676,1.431s-0.81,1.401-0.974,2.305h9.072v-0.222c-0.082-0.838-0.349-1.558-0.801-2.157
			s-1.011-1.056-1.677-1.368c-0.666-0.312-1.36-0.469-2.083-0.469C140.29,137.264,139.669,137.424,139.036,137.745z"
                  />
                  <path
                    d="M148.837,146.534c0-0.114,0.065-0.222,0.197-0.32c0.082-0.082,0.181-0.123,0.296-0.123
			c0.164,0,0.296,0.065,0.395,0.197c0.953,1.364,2.284,2.046,3.994,2.046c0.871,0,1.635-0.218,2.292-0.653
			c0.657-0.435,0.986-1.022,0.986-1.763c0-0.756-0.287-1.322-0.862-1.701c-0.576-0.378-1.364-0.69-2.367-0.937
			c-1.331-0.328-2.379-0.769-3.144-1.319c-0.764-0.55-1.146-1.327-1.146-2.329c0-0.97,0.387-1.771,1.159-2.404
			c0.772-0.632,1.767-0.949,2.983-0.949c0.674,0,1.344,0.132,2.009,0.395c0.666,0.264,1.27,0.683,1.812,1.258
			c0.114,0.099,0.172,0.214,0.172,0.345c0,0.148-0.058,0.28-0.172,0.395c-0.132,0.066-0.238,0.099-0.32,0.099
			c-0.115,0-0.214-0.049-0.296-0.147c-0.855-0.938-1.956-1.405-3.304-1.405c-0.872,0-1.607,0.214-2.207,0.641
			c-0.6,0.428-0.899,1.02-0.899,1.775c0.049,0.707,0.373,1.253,0.974,1.64c0.6,0.386,1.483,0.727,2.65,1.022
			c0.838,0.214,1.532,0.448,2.083,0.703s0.99,0.612,1.319,1.072c0.328,0.46,0.493,1.06,0.493,1.8c0,1.035-0.399,1.854-1.196,2.453
			s-1.837,0.899-3.118,0.899c-1.874,0-3.411-0.739-4.61-2.219C148.894,146.822,148.837,146.665,148.837,146.534z"
                  />
                  <path
                    d="M168.572,136.525c0.666,0.164,1.199,0.382,1.603,0.653c0.402,0.271,0.604,0.555,0.604,0.851
			c0,0.132-0.045,0.246-0.135,0.345c-0.091,0.099-0.202,0.148-0.333,0.148c-0.148,0-0.259-0.025-0.333-0.074
			c-0.074-0.05-0.177-0.14-0.309-0.271c-0.214-0.246-0.436-0.427-0.665-0.542c-0.592-0.279-1.431-0.419-2.515-0.419
			c-0.938,0-1.805,0.246-2.602,0.739s-1.43,1.163-1.898,2.01c-0.468,0.847-0.702,1.771-0.702,2.773c0,1.052,0.218,2.001,0.653,2.847
			c0.436,0.848,1.052,1.509,1.849,1.985s1.697,0.715,2.7,0.715c0.657,0,1.175-0.058,1.553-0.173
			c0.378-0.114,0.756-0.279,1.134-0.493c0.263-0.164,0.493-0.345,0.69-0.542c0.082-0.082,0.164-0.152,0.247-0.21
			c0.082-0.058,0.164-0.086,0.246-0.086c0.132,0,0.234,0.041,0.309,0.123c0.074,0.083,0.11,0.189,0.11,0.32
			c0,0.23-0.188,0.506-0.566,0.826c-0.379,0.32-0.9,0.596-1.565,0.826c-0.666,0.229-1.41,0.345-2.231,0.345
			c-1.184,0-2.239-0.279-3.168-0.838s-1.652-1.331-2.17-2.317s-0.776-2.096-0.776-3.328c0-1.15,0.259-2.219,0.776-3.205
			s1.249-1.775,2.194-2.367s2.025-0.888,3.242-0.888C167.22,136.278,167.906,136.361,168.572,136.525z"
                  />
                  <path
                    d="M183.845,146.152c0.082,0.091,0.123,0.193,0.123,0.309s-0.033,0.214-0.099,0.296
			c-0.542,0.723-1.216,1.314-2.021,1.774s-1.668,0.69-2.589,0.69c-1.232,0-2.325-0.263-3.278-0.789
			c-0.954-0.525-1.697-1.273-2.231-2.243s-0.801-2.096-0.801-3.378c0-1.314,0.259-2.469,0.776-3.464
			c0.518-0.994,1.203-1.754,2.059-2.28c0.854-0.525,1.767-0.789,2.736-0.789s1.869,0.21,2.699,0.629s1.508,1.048,2.034,1.886
			c0.526,0.839,0.806,1.874,0.839,3.106c0,0.132-0.05,0.251-0.148,0.357c-0.099,0.107-0.214,0.16-0.345,0.16h-9.689v0.321
			c0,1.019,0.205,1.951,0.616,2.798s1.02,1.517,1.824,2.01c0.806,0.492,1.775,0.739,2.909,0.739c0.789,0,1.533-0.193,2.231-0.579
			c0.698-0.387,1.245-0.876,1.64-1.468c0.114-0.147,0.255-0.222,0.419-0.222C183.664,146.016,183.763,146.062,183.845,146.152z
			 M176.658,137.745c-0.633,0.321-1.191,0.798-1.677,1.431c-0.484,0.633-0.81,1.401-0.974,2.305h9.072v-0.222
			c-0.082-0.838-0.349-1.558-0.801-2.157s-1.011-1.056-1.677-1.368c-0.665-0.312-1.36-0.469-2.083-0.469
			C177.911,137.264,177.291,137.424,176.658,137.745z"
                  />
                  <path
                    d="M196.221,137.573c0.74,0.862,1.109,1.984,1.109,3.365v7.544c0,0.132-0.049,0.246-0.147,0.345
			s-0.214,0.148-0.346,0.148c-0.147,0-0.267-0.05-0.357-0.148c-0.09-0.099-0.136-0.213-0.136-0.345v-7.445
			c0-1.118-0.292-2.034-0.875-2.749s-1.467-1.072-2.65-1.072c-0.723,0-1.434,0.172-2.132,0.518
			c-0.699,0.345-1.271,0.813-1.714,1.405s-0.666,1.225-0.666,1.898v7.445c0,0.132-0.049,0.246-0.147,0.345s-0.214,0.148-0.345,0.148
			c-0.148,0-0.268-0.05-0.358-0.148c-0.09-0.099-0.135-0.213-0.135-0.345v-11.316c0-0.131,0.049-0.246,0.147-0.345
			s0.214-0.148,0.346-0.148c0.147,0,0.267,0.05,0.357,0.148c0.09,0.099,0.135,0.214,0.135,0.345v1.652
			c0.477-0.74,1.146-1.348,2.01-1.825c0.863-0.477,1.729-0.715,2.601-0.715C194.38,136.278,195.481,136.709,196.221,137.573z"
                  />
                  <path
                    d="M208.831,136.525c0.666,0.164,1.199,0.382,1.603,0.653c0.402,0.271,0.604,0.555,0.604,0.851
			c0,0.132-0.045,0.246-0.135,0.345c-0.091,0.099-0.202,0.148-0.333,0.148c-0.148,0-0.259-0.025-0.333-0.074
			c-0.074-0.05-0.177-0.14-0.309-0.271c-0.214-0.246-0.436-0.427-0.665-0.542c-0.592-0.279-1.431-0.419-2.515-0.419
			c-0.938,0-1.805,0.246-2.602,0.739s-1.43,1.163-1.898,2.01c-0.468,0.847-0.702,1.771-0.702,2.773c0,1.052,0.218,2.001,0.653,2.847
			c0.436,0.848,1.052,1.509,1.849,1.985s1.697,0.715,2.7,0.715c0.657,0,1.175-0.058,1.553-0.173
			c0.378-0.114,0.756-0.279,1.134-0.493c0.263-0.164,0.493-0.345,0.69-0.542c0.082-0.082,0.164-0.152,0.247-0.21
			c0.082-0.058,0.164-0.086,0.246-0.086c0.132,0,0.234,0.041,0.309,0.123c0.074,0.083,0.11,0.189,0.11,0.32
			c0,0.23-0.188,0.506-0.566,0.826c-0.379,0.32-0.9,0.596-1.565,0.826c-0.666,0.229-1.41,0.345-2.231,0.345
			c-1.184,0-2.239-0.279-3.168-0.838s-1.652-1.331-2.17-2.317s-0.776-2.096-0.776-3.328c0-1.15,0.259-2.219,0.776-3.205
			s1.249-1.775,2.194-2.367s2.025-0.888,3.242-0.888C207.478,136.278,208.165,136.361,208.831,136.525z"
                  />
                  <path
                    d="M224.103,146.152c0.082,0.091,0.123,0.193,0.123,0.309s-0.033,0.214-0.099,0.296
			c-0.542,0.723-1.216,1.314-2.021,1.774s-1.668,0.69-2.589,0.69c-1.232,0-2.325-0.263-3.278-0.789
			c-0.954-0.525-1.697-1.273-2.231-2.243s-0.801-2.096-0.801-3.378c0-1.314,0.259-2.469,0.776-3.464
			c0.518-0.994,1.203-1.754,2.059-2.28c0.854-0.525,1.767-0.789,2.736-0.789s1.869,0.21,2.699,0.629s1.508,1.048,2.034,1.886
			c0.526,0.839,0.806,1.874,0.839,3.106c0,0.132-0.05,0.251-0.148,0.357c-0.099,0.107-0.214,0.16-0.345,0.16h-9.689v0.321
			c0,1.019,0.205,1.951,0.616,2.798s1.02,1.517,1.824,2.01c0.806,0.492,1.775,0.739,2.909,0.739c0.789,0,1.533-0.193,2.231-0.579
			c0.698-0.387,1.245-0.876,1.64-1.468c0.114-0.147,0.255-0.222,0.419-0.222C223.923,146.016,224.021,146.062,224.103,146.152z
			 M216.917,137.745c-0.633,0.321-1.191,0.798-1.677,1.431c-0.484,0.633-0.81,1.401-0.974,2.305h9.072v-0.222
			c-0.082-0.838-0.349-1.558-0.801-2.157s-1.011-1.056-1.677-1.368c-0.665-0.312-1.36-0.469-2.083-0.469
			C218.17,137.264,217.55,137.424,216.917,137.745z"
                  />
                </g>
                <g id="sun">
                  <g
                    className="SGVsunLine"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    fill="none"
                  >
                    <line
                      strokeWidth="0.6"
                      x1="141.732"
                      y1="49.204"
                      x2="141.732"
                      y2="119.628"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="145.019"
                      y1="52.896"
                      x2="138.446"
                      y2="115.936"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="148.996"
                      y1="49.961"
                      x2="134.469"
                      y2="118.871"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="151.45"
                      y1="54.252"
                      x2="132.015"
                      y2="114.58"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="155.947"
                      y1="52.201"
                      x2="127.518"
                      y2="116.631"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="157.463"
                      y1="56.905"
                      x2="126.002"
                      y2="111.927"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="162.286"
                      y1="55.826"
                      x2="121.178"
                      y2="113.006"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="162.8"
                      y1="60.742"
                      x2="120.665"
                      y2="108.09"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="167.742"
                      y1="60.68"
                      x2="115.723"
                      y2="108.152"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="167.23"
                      y1="65.597"
                      x2="116.235"
                      y2="103.235"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="172.079"
                      y1="66.556"
                      x2="111.386"
                      y2="102.276"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="170.564"
                      y1="71.261"
                      x2="112.901"
                      y2="97.571"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="175.11"
                      y1="73.2"
                      x2="108.354"
                      y2="95.632"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="172.657"
                      y1="77.491"
                      x2="110.807"
                      y2="91.341"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="176.706"
                      y1="80.327"
                      x2="106.759"
                      y2="88.505"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="173.421"
                      y1="84.019"
                      x2="110.044"
                      y2="84.813"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="176.797"
                      y1="87.629"
                      x2="106.667"
                      y2="81.203"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="172.821"
                      y1="90.565"
                      x2="110.644"
                      y2="78.267"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="175.38"
                      y1="94.793"
                      x2="108.084"
                      y2="74.039"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="170.884"
                      y1="96.845"
                      x2="112.581"
                      y2="71.987"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="172.516"
                      y1="101.511"
                      x2="110.948"
                      y2="67.321"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="167.693"
                      y1="102.591"
                      x2="115.771"
                      y2="66.241"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="168.328"
                      y1="107.493"
                      x2="115.137"
                      y2="61.339"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="163.386"
                      y1="107.556"
                      x2="120.079"
                      y2="61.276"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="162.996"
                      y1="112.483"
                      x2="120.469"
                      y2="56.349"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="158.147"
                      y1="111.525"
                      x2="125.318"
                      y2="57.307"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="156.749"
                      y1="116.266"
                      x2="126.716"
                      y2="52.567"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="152.202"
                      y1="114.327"
                      x2="131.263"
                      y2="54.505"
                    />
                    <line
                      strokeWidth="0.6"
                      x1="149.856"
                      y1="118.678"
                      x2="133.609"
                      y2="50.154"
                    />
                    <line
                      strokeWidth="0.5"
                      x1="145.477"
                      y1="115.885"
                      x2="137.987"
                      y2="52.947"
                    />
                  </g>
                  <circle
                    className="SVGCircle"
                    fill="#FFFFFF"
                    strokeWidth="1.1246"
                    strokeMiterlimit="10"
                    cx="141.732"
                    cy="84.416"
                    r="10.951"
                  />
                </g>
              </g>
            </svg>
          </div>
        </Link>
      </StyledSiteHeadCenter>
    </>
  );
};

export default NavLogo;
