const theme = {
  useColorSchemeMediaQuery: true,
  colors: {
    white: "#fff",
    black: "#3c3c3c",
    gold: "#debf99",
    darkGold: "#85735c",
    green: "#acb7ae",
    darkGreen: "#707872",
    lightGreen: "#ced4ce",
    reikigrey: "#8c8480",
    reikiDarkGrey: "#544f4d",
    taupe: "#cfbcb3",
    darkTaupe: "#7c716b",
    lightGrey: "#f7f8f8",
    bcgFillColor: "#acb7ae",

    // à vérifier
    grey: "rgb(115, 115, 115)",
    greyText: "#677178",
    background: "#fff",
  },
  fonts: {
    body: '"Inter var",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    LarkenThin: "Larken-Thin",
    LarkenThinItalic: "Larken-ThinItalic",
    LarkenLight: "Larken-Light",
    LarkenLightItalic: "Larken-LightItalic",
    LarkenRegular: "Larken-Regular",
    LarkenItalic: "Larken-Italic",
  },
  fontWeights: {
    extraLight: 100,
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
  },
  lineHeights: {
    bodyCopy: 1.425,
    headingDesktop: 1.125,
    headingMobile: 1.125,
    bigText: 1.8,
  },
  letterSpacings: {
    subHeading: "0.08em",
    bodyCopy: "0.4px",
  },
  fontSizes: {
    bodyCopy: "0.9em",
    headingDesktop: "3em",
    headingMobile: "2em",
    subHeading: "0.72em",
    bigText: "1.2em",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    // maxWidth: '1440px',
    smallMaxWidth: "668px",
    mediumMaxWidth: "1200px",
    maxWidth: "1510px",
    // borderRadius: '10px',
    borderRadius: "1rem",
    strokeWidth: 1.8,
    vSpacing: "10vh",
    // hGutter: '6.6vw',
    hGutter: "3.6vw",
  },
  responsive: {
    small: "35em",
    medium: "50em",
    large: "80em",
  },
  animation: {
    easing: "cubic-bezier(0.5, 0.7, 0.4, 1)",
  },
};
export default theme;
