import React, { createContext, useState } from 'react'

export const AppContext = createContext({
  mobileNavOpen: false,
  toggleMobileNav: () => {},
  showMobileNavMenu: false,
  toggleMobileNavView: () => {},
})

export const AppProvider = ({ children }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  // The mobileNavOpen state is in charge of knowing when to display the mobile nav links to a user.

  const [showMobileNavMenu, setShowMobileNavMenu] = useState(false)
  // The showMobileNavMenu state is responsible for determining whether to show the hamburger bar for the mobile menu or not.

  // const [subNavOpen, setSubNavOpen] = useState(false)
  return (
    <AppContext.Provider
      value={{
        mobileNavOpen,
        setMobileNavOpen,
        showMobileNavMenu,
        setShowMobileNavMenu,
        toggleMobileNav: (navState) => setMobileNavOpen(navState),
        toggleMobileNavView: (showNavBool) => setShowMobileNavMenu(showNavBool),
        // subNavOpen,
        // setSubNavOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
